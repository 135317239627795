import { Exposures } from '../../credentail-services/model/credentail.model';

export interface ProfileModel {
  id: number,
  email: string,
  fb:  string,
  linkedin: string
  role_id:  number,
  dp: string,
  education: Exposures[],
  work: Exposures[],
  profile_attributes: {
    logo: string
    cover: string
    first_name: string,
    last_name: string,
    name: string
    bio: string,
    dob: string,
    id: string,
    occupation: string,
    rank: string
  }
}

export interface ProfileOverView {
  current_user_id: number
  total_comments:number
  total_norbus:number
  total_posts:number
  total_publications:number
  total_questions:number
  total_moodboard: number
}
