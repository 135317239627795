import React, { lazy, Suspense, useEffect } from 'react';
import { RouteModel } from '../shared/model/shared.model';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { switchLanguage } from '../i18n';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@new-naykab-gokab/data';
import Loader from '../shared/loader/loader';
import { Grid } from '@mui/material';

const RegistrationRoute = lazy( () => import( '../pages/auth/route/registration.route' ) );
const Home = lazy( () => import( '../pages/user/route/user.route' ) );
const LayerTwo = lazy( () => import( '../pages/user/route/seacond-layer.route' ) );
export function CoreRoute (): JSX.Element {
 const user = useSelector( selectCurrentUser );
 const path = useLocation().pathname;
 const nav = useNavigate();

 const AUTHENTICATED: RouteModel[] = [
  {
   id: '1',
   path: '/*',
   component: <LayerTwo />
  },
 ];

 const UNAUTHENTICATED: RouteModel[] = [
  {
   id: '1',
   path: '/*',
   component: <Home />
  },
  {
   id: '2',
   path: 'auth/*',
   component: <RegistrationRoute/>
  }
 ];

 useEffect( () => {
  switchLanguage( 'en' );
 },[] );

 useEffect( () => {
  if ( user?.email && path === '/auth' ) {
   nav( '/' );
  }
 }, [user] );
 return (
  <Suspense fallback={
   <Grid container justifyContent='center' className='mt-24'>
    <Loader/>
   </Grid>
  }>
   <Routes>
    {
     ( user?.email? AUTHENTICATED : UNAUTHENTICATED ).map( ( { id,path, component } ) =>
      <Route key={id} path={ path } element={ component }/> )
    }
   </Routes>
  </Suspense>
 );

}

export default CoreRoute;
