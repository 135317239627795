import { FetchAPI } from '../base-api-services/base-api.service';

export const getExplorePostAPI = ( payload: FormData, query?: string, post?: string ): unknown => {
 return FetchAPI( query?.length? `api/v1/posts/query_posts?query=${query}` : `api/v1/posts/query_posts?per_page=${query || 10}&page_number=${post || 1}`, 'POST',
  { body: payload }  );
};

export const postCreationAPI = ( payload: FormData ) => FetchAPI( 'api/v1/posts', 'POST', { body: payload } );

export const postEditAPI = ( payload: FormData, post_id: string ) => FetchAPI( `api/v1/posts/${post_id}`, 'PUT', { body: payload } );

export const fetchPostByIdAPI = ( id: number ) => FetchAPI( `api/v1/posts/${id}`, 'GET' );

export const postCommentsAPI = ( payload: FormData, post_id:  number ) => FetchAPI(
 `api/v1/posts/${post_id}/comments`, 'POST', { body: payload } );

export const getPostCommentAPI = ( post_id: number ) => FetchAPI( `api/v1/posts/${post_id}/comments`, 'GET' );
export const postVerificationAPI = ( page:any, number: any ) => FetchAPI( `api/v1/verification_requests?per_page=${page}&page_number=${number}`, 'GET' );

export const getReportedPostAPI = () => FetchAPI( 'api/v1/reports', 'GET' );

export const sendForVerifyAPI = ( payload: FormData ) => FetchAPI(
 'api/v1/verification_requests', 'POST', { body: payload } );

export const myPostAPI = ( post_type: string ) => FetchAPI(
 `api/v1/posts/my_posts?type=${post_type}`, 'GET'
);

export const deleteMyPostAPI = ( post_id: string ) => FetchAPI(
 `api/v1/posts/${post_id}`, 'DELETE'
);

export const verifyPostAPI = ( vr_id: number, payload: FormData ) => FetchAPI(
 `api/v1/posts/${vr_id}/verify`, 'PUT'
);

export const deleteCommentsAPI = ( id: any ) => FetchAPI( `api/v1/posts/{post_id}/comments/${id}`, 'DELETE' );

export const updateCommentsAPI = ( payload: FormData, id: any, postId: any ) => FetchAPI( `api/v1/posts/${postId}/comments/${id}`, 'PUT', { body: payload } );

export const newsAndEvent = ( type: string ) => FetchAPI( `api/v1/news_events?news_event_type=${type}`, 'GET' );
