import { serialize } from 'object-to-formdata';
import {
 fetchPostByIdAPI,
 getExplorePostAPI,
 getPostCommentAPI,
 postCommentsAPI,
 postCreationAPI,
 getReportedPostAPI,
 postVerificationAPI,
 sendForVerifyAPI,
 myPostAPI,
 deleteMyPostAPI,
 postEditAPI,
 verifyPostAPI,
 deleteCommentsAPI, updateCommentsAPI, newsAndEvent,
} from './explore-services';

export class ExploreFacadeService {

 static getExplorePost ( type: any, query?: string, post?: string ): any {
  return getExplorePostAPI( serialize( type ), query, post  );
 }

 static postCreation ( payload: any ): any {
  return postCreationAPI( serialize( payload ) );
 }

 static postEdit ( payload: any, id: any ): any {
  return postEditAPI( serialize( payload ), id );
 }

 static fetchPostByID ( id: number ): any {
  return fetchPostByIdAPI( id );
 }

 static getPostComments ( id: number ): any {
  return getPostCommentAPI( id );
 }

 static postComments ( payload: any , id: number ): any {
  return postCommentsAPI( serialize( payload ), id );
 }

 static deleteComment ( id: any ): any {
  return deleteCommentsAPI( id );
 }

 static updateComment ( payload: any, id: any, postID: any ): any {
  return updateCommentsAPI( serialize( payload ), id, postID );
 }

 static getReportedPost (): any {
  return getReportedPostAPI();
 }
 static postVerifiedData ( page: any, number: any ): any {
  return postVerificationAPI( page, number );
 }

 static sendForVerify ( payload: any ): any {
  return sendForVerifyAPI( serialize( payload ) );
 }

 static getMyPost ( payload: string ): any {
  return myPostAPI( payload );
 }

 static deleteMyPost ( payload: string ): any {
  return deleteMyPostAPI( payload );
 }

 static verifyPost ( data: { id: number, payload: any } ): any {
  return verifyPostAPI( data.id, serialize( data.payload ) );
 }

 static newsAndEvent ( type?: any ): any {
  return newsAndEvent( type );
 }
}
