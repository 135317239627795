import CoreRoute from './core-route/core-route';
import { Grid, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { theme } from '../assets/theme';

export function App (): JSX.Element {
 return (
  <StyledEngineProvider injectFirst>
   <ThemeProvider theme={ theme }>
    <Grid container className='height--full'>
     <Grid item xs={12} className='height--full'>
      <CoreRoute />
     </Grid>
    </Grid>
   </ThemeProvider>
  </StyledEngineProvider>
 );
}

export default App;
