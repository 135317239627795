import { FetchAPI } from '../base-api-services/base-api.service';

export const LoginAPI = ( payload: FormData ): unknown => FetchAPI( 'users/sign_in', 'POST', { body: payload } );

export const LogOutAPI = () => FetchAPI( 'users/sign_out', 'delete' );

export const SignUpAPI = ( payload: FormData ) => FetchAPI( 'users', 'POST', { body: payload } );

export const UserVerificationAPI = ( confirmation_token: string, email: string ) => FetchAPI( `users/sign_up?confirmation_token=${confirmation_token}&email=${encodeURIComponent( email )}`,
 'GET' );

export const resetPasswordToken = ( email: string, token: string ) => FetchAPI( `users/password/new?email=${encodeURIComponent( email )}&reset_password_token=${token}`, 'GET' );

export const UserRegistrationAPI = ( payload: FormData, uid?: number ): any => FetchAPI( `api/v1/profiles/${uid}`,
 'PUT', { body: payload } );

export const uploadDPAPI = ( payload: any ) => FetchAPI( 'api/v1/profiles/upload_picture', 'put', { body: payload } );

export const resetPasswordAPI = ( email: string ) => FetchAPI( `users/password?email=${encodeURIComponent( email )}`, 'POST' );

export const resetNewPasswordAPI = ( payload: FormData ) => FetchAPI( 'users/password', 'PUT', { body: payload } );

export const resendCodeAPI = ( payload: FormData ): any => FetchAPI( 'api/v1/users/resend_code_email', 'POST', { body: payload } );

export const deleteUserAPI = ( payload: any ): any => FetchAPI( `/api/v1/users/${payload}`, 'DELETE' );

export const blockUserAPI = ( payload: any ): any => FetchAPI( `/api/v1/users/${payload}/block`, 'PUT' );
