import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { dz, en } from '.';

const resources: Resource = {
 dz: {
  translation: dz,
 },
 en: {
  translation: en,
 }
};

i18n.use( initReactI18next ).init( {
 resources,
 fallbackLng: 'en',
 lng: 'en',
 interpolation: {
  escapeValue: false,
 },
} );

export const switchLanguage = ( locale: string ): void => {
 i18n.changeLanguage( locale ).then();
};

export default i18n;
