import { combineReducers, configureStore, Store } from '@reduxjs/toolkit';
import { PersistConfig, Persistor, persistReducer } from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import { CombinedState, Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import {
 CREDENTIAL_KEY,
 TAB_KEY,
 credentialReducer,
 tabReducer,
 EXPLORE_KEY,
 exploreReducer,
 InitialCredentialState,
 InitialTabState,
 InitialExploreState,
 InitialSharedState,
 SHARED_KEY,
 sharedReducer,
 PROFILE_KEY, profileReducer, InitialProfileState
} from '@new-naykab-gokab/data';
import { useDispatch } from 'react-redux';


export interface IReducer {
  [CREDENTIAL_KEY]: InitialCredentialState;
  [SHARED_KEY]: InitialSharedState;
  [EXPLORE_KEY]: InitialExploreState;
  [PROFILE_KEY]: InitialProfileState;
  [TAB_KEY]: InitialTabState;
}

const persistConfig: PersistConfig<IReducer> = {
 key: 'root',
 storage,
 whitelist: [ CREDENTIAL_KEY, TAB_KEY ]
};

const reducers: Reducer<CombinedState<IReducer>> = combineReducers( {
 [CREDENTIAL_KEY]: credentialReducer,
 [SHARED_KEY]: sharedReducer,
 [EXPLORE_KEY]: exploreReducer,
 [PROFILE_KEY]: profileReducer,
 [TAB_KEY]: tabReducer
} );

const persistedReducer: Reducer<IReducer & PersistPartial> = persistReducer( persistConfig, reducers );

export const store: Store = configureStore( {

 reducer: persistedReducer,
 middleware: ( getDefaultMiddleware ) => getDefaultMiddleware( {
  serializableCheck: false
 } ),
} );

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): any => useDispatch<AppDispatch>();

export const persistor: Persistor = persistStore( store );

export type RootState = ReturnType<typeof store.getState>
