export const EDUCATION = [
 {
  id: '0',
  name: 'Select',
  value: 'lower_school'
 },
 {
  id: '1',
  name: 'Lower School',
  value: 'lower_school'
 },
 {
  id: '2',
  name: 'Middle School',
  value: 'middle_school'
 },
 {
  id: '3',
  name: 'High School',
  value: 'high_school'
 },
 {
  id: '4',
  name: 'Diploma',
  value: 'diploma'
 },
 {
  id: '5',
  name: 'Degree',
  value: 'degree'
 },
 {
  id: '6',
  name: 'Masters',
  value: 'masters'
 },
 {
  id: '7',
  name: 'Phd',
  value: 'phd'
 },
];
