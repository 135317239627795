import { serialize } from 'object-to-formdata';
import {
 LoginAPI,
 LogOutAPI,
 SignUpAPI,
 uploadDPAPI,
 UserRegistrationAPI,
 userProfileAPI, profileOverViewAPI, getUserProfileAPI, getUsersAPI
} from './profile-services';
import { LoginWithEmailData, SignUpFormData } from '../shared-service/model/shared-data.model';
import { ProfileSetUp } from '../credentail-services/model/credentail.model';

export class ProfileFacadeService {

 static userSignIn ( payload: LoginWithEmailData ): any {
  return LoginAPI( serialize( payload ) );
 }

 static UserLogout ( ): any {
  return LogOutAPI();
 }

 static UserSignup ( payload:SignUpFormData ): any {
  return SignUpAPI( serialize( payload ) );
 }

 static uploadProfile ( payload: any ): any {
  const data = {
   profile: payload.profile
  };
  return uploadDPAPI( serialize( data ) );
 }

 static UserProfile ( ): any {
  return userProfileAPI( );
 }

 static profileOverView ( ): any {
  return profileOverViewAPI( );
 }

 static userProfile ( id: any ): any {
  return getUserProfileAPI( id );
 }

 static UserRegistration ( payload: ProfileSetUp ): any {
  const data = {
   profile: payload.profile
  };
  return UserRegistrationAPI( serialize( data ), payload.uid );
 }

 static UsersListing ( page?: number, per_page?: number ): any {
  return getUsersAPI( page, per_page );
 }

}
