import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { ProfileSetUp, RegistrationForm, UserModel } from '../model/credentail.model';
import { CredentialFacadeService } from '../credentail-facade.service';
import { LoginWithEmailData } from '../../shared-service/model/shared-data.model';
import { setRegistrationForm, setUser } from './credentail-state.action';
import { loginDataModifier } from '../credentail-bl.services';
import { ProfileFacadeService } from '../../profile-services/profile-facade.service';
import { dataModifier } from '../../profile-services/profile-bl.services';

export const CREDENTIAL_KEY = 'auth';

export interface InitialCredentialState {
  activeUser: UserModel | null;
  loading: boolean;
  resetLink: boolean;
  resetPassword: boolean;
  registrationForm: RegistrationForm | null
  loginStatus: string;
  setUpStatus: string

}

export const INITIAL_CREDENTIAL_VALUE: InitialCredentialState = {
 activeUser: null,
 resetLink: false,
 loading: false,
 resetPassword: false,
 registrationForm: null,
 loginStatus: 'initial',
 setUpStatus: 'initial'
};

export const UserLogin = createAsyncThunk(
 'auth/userLogin',
 async ( payload: LoginWithEmailData, thunkAPI ) => {
  const { data, error } = await CredentialFacadeService.userSignIn( payload );
  if ( data ) {
   return loginDataModifier( data );
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const UserVerification = createAsyncThunk(
 'auth/userVerification',
 async ( payload: { token: string, email: string }, thunkAPI ) => {
  const { data, error } = await CredentialFacadeService.UserVerification( payload.token, payload.email );
  if ( data ) return data;
  if ( error ) return error;
 }
);

export const UserLogOut = createAsyncThunk(
 'auth/userLogOut',
 async ( payload: void, thunkAPI ) => {
  const { data, error } = await CredentialFacadeService.UserLogout();
  if ( data ) return data;
  if ( error )  return thunkAPI.rejectWithValue( error.errors );

 }
);

export const UserRegistration = createAsyncThunk(
 'profile/userRegistrationForm',
 async ( payload: ProfileSetUp , thunkAPI ) => {
  const { data, error } = await ProfileFacadeService.UserRegistration( payload );
  if ( data ) return dataModifier( data );

  if ( error ) return thunkAPI.rejectWithValue( error.errors );
 }
);

export const CredentialSlice = createSlice( {
 name: CREDENTIAL_KEY,
 initialState: INITIAL_CREDENTIAL_VALUE,
 reducers: {},
 extraReducers: builder => {
  builder
   .addCase( UserLogin.pending, ( state, action ) => {
    state.loginStatus = 'loading';
   } )
   .addCase( UserLogin.fulfilled, ( state, action ) => {
    state.activeUser = action.payload;
    state.loginStatus = 'loaded';
   } )
   .addCase( UserLogin.rejected, ( state, action ) => {
    state.loginStatus = 'reject';
   } )
   .addCase( UserLogOut.pending, ( state, action ) => {
    state.loginStatus = 'loading';
   } )
   .addCase( UserLogOut.fulfilled, ( state, action ) => {
    state.loginStatus = 'loaded';
    state.activeUser = null;
   } )
   .addCase( UserLogOut.rejected, ( state, action ) => {
    state.loginStatus = 'rejected';
   } )
   .addCase( UserVerification.fulfilled, ( state, action ) => {
    state.activeUser = action.payload;
   } )
   .addCase( UserRegistration.pending, ( state, action ) => {
    state.setUpStatus = 'loading';
   } )
   .addCase( UserRegistration.fulfilled, ( state, action ) => {
    state.activeUser = action.payload;
    state.setUpStatus = 'loaded';
   } )
   .addCase( UserRegistration.rejected, ( state, action ) => {
    state.setUpStatus = 'rejected';
   } )
   .addCase( setRegistrationForm, ( state, action ) => {
    state.registrationForm = action.payload;
   }
   )
   .addCase( setUser, ( state, action ) => {
    state.activeUser = action.payload;
   } )
  ;
 }
} );

export const credentialReducer = CredentialSlice.reducer;
export const getAuthState = ( rootState: any ): InitialCredentialState => rootState[ CREDENTIAL_KEY ];
export const selectCurrentUser = createSelector( getAuthState, state => state.activeUser );
export const selectLoginStatus = createSelector( getAuthState, state => state.loginStatus );
export const selectRegistrationForm = createSelector( getAuthState, state => state.registrationForm );
export const selectRFStatus = createSelector( getAuthState, state => state.setUpStatus );
