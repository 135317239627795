import { FetchAPI } from '../base-api-services/base-api.service';

export const LoginAPI = ( payload: FormData ): unknown => FetchAPI( 'users/sign_in', 'POST', { body: payload } );

export const LogOutAPI = () => FetchAPI( '/users/sign_out', 'DELETE' );

export const SignUpAPI = ( payload: FormData ) => FetchAPI( 'users', 'POST', { body: payload } );

export const userProfileAPI = ( ) => FetchAPI( 'api/v1/profiles',
 'GET' );

export const UserRegistrationAPI = ( payload: FormData, uid?: number ): any => FetchAPI( `api/v1/profiles/${uid}`,
 'PUT', { body: payload } );

export const uploadDPAPI = ( payload: any ) => FetchAPI( 'api/v1/profiles/upload_picture', 'PUT', { body: payload } );

export const profileOverViewAPI = ( ) => FetchAPI( 'api/v1/profiles/overview', 'GET' );

export const getUserProfileAPI = ( id: any ) => FetchAPI( `api/v1/profiles?user_id=${id}`, 'GET' );

export const getUsersAPI = ( page?: number, per_page?: number ) => FetchAPI( `api/v1/users/all_users?page_number=${page}&per_page=${per_page}`, 'GET' );
