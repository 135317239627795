import { createSelector, createSlice } from '@reduxjs/toolkit';
import { setTabChange } from './tab-action';

export const TAB_KEY = 'tab';

export interface InitialTabState {
  tabNumber: number
}

export const INITIAL_TAB_VALUE: InitialTabState = {
 tabNumber: 0

};



export const TabSlice = createSlice( {
 name: TAB_KEY,
 initialState: INITIAL_TAB_VALUE,
 reducers: {},
 extraReducers: builder => {
  builder
   .addCase( setTabChange, ( state, action ) => {
    state.tabNumber = action.payload;
   } )

  ;
 }
} );

export const tabReducer = TabSlice.reducer;
export const getTabState = ( rootState: any ): InitialTabState => rootState[ TAB_KEY ];
export const selectCurrentTab = createSelector( getTabState, state => state.tabNumber );

