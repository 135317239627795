import { serialize } from 'object-to-formdata';
import {
 deleteTestimonialAPI,
 dzongkhagDetailAPI, getAdminTestimonialAPI,
 getTagListAPI, getTestimonialAPI, postEnquiresAPI,
 postLikeAPI,
 postReportAPI,
 postUnlikeAPI,
 topInnovatorsAPI, topSolutionAPI, verifiedTestimonial
} from './shared-service';

export class SharedFacadeService {

 static getTagList ( ): any {
  return getTagListAPI( );
 }

 static postReport ( payload: any ): any {
  return postReportAPI( serialize( payload ) );
 }

 static postLike ( payload: number | string ): any {
  return postLikeAPI( payload  );
 }

 static postUnlike ( payload: number | string ): any {
  return postUnlikeAPI( payload );
 }

 static dzongkhagDetail ( name: string ): any {
  return dzongkhagDetailAPI( name );
 }

 static topInnovators ( ): any {
  return topInnovatorsAPI();
 }

 static getTestimonial ( type:string, isAdmin?: boolean, page?: number, per_page?: number ): any {
  return isAdmin ? getAdminTestimonialAPI( type, page, per_page ) :  getTestimonialAPI( type );
 }

 static postEnquiries ( payload: any ) : any {
  return postEnquiresAPI( serialize( payload ) );
 }

 static getTopSolution (): any {
  return topSolutionAPI();
 }

 static testimonialVerified ( id: number ): any {
  return verifiedTestimonial( id );
 }

 static deleteTestimonial ( id: number ): any {
  return deleteTestimonialAPI( id );
 }
}

