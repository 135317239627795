import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { ProfileModel, ProfileOverView } from '../model/profile.model';
import { setRegistrationForm } from './profile-state.action';
import { dataModifier } from '../profile-bl.services';
import { ProfileFacadeService } from '../profile-facade.service';
import { ProfileSetUp } from '../../credentail-services/model/credentail.model';
import toast from 'react-hot-toast';

export const PROFILE_KEY = 'profile';

export interface InitialProfileState {
  user: ProfileModel | null;
  profileStatus: string
  profileOverView: ProfileOverView | null
  userListing: any;
  loading: boolean;
}

export const INITIAL_PROFILE_VALUE: InitialProfileState = {
 user: null,
 profileStatus: 'init',
 profileOverView: null,
 userListing: undefined,
 loading: false

};

export const getUserProfile = createAsyncThunk(
 'profile/getUser',
 async ( payload:void, thunkAPI ) => {
  const { data, error } = await ProfileFacadeService.UserProfile();
  if ( data ) {
   return dataModifier( data );
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const uploadProfile = createAsyncThunk(
 'profile/uploadProfile',
 async ( payload: any, thunkAPI ) => {
  const { data , error } = await ProfileFacadeService.uploadProfile( payload );
  if ( data ) {
   return dataModifier( data );
  }
  if ( error ) {
   toast.error( 'Sorry, Image size is too large to upload. Please try another one' );
   return thunkAPI.rejectWithValue( error.errors );
  }
  return null;
 }
);

export const UserRegistrationForm = createAsyncThunk(
 'profile/userRegistrationForm',
 async ( payload: ProfileSetUp , thunkAPI ) => {
  const { data, error } = await ProfileFacadeService.UserRegistration( payload );
  if ( data ) return dataModifier( data );

  if ( error ) return thunkAPI.rejectWithValue( error.errors );
 }
);

export const UsersListing = createAsyncThunk(
 'profile/UsersListing',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await ProfileFacadeService.UsersListing( payload.page, payload.per_page );
  if ( data ) return data;

  if ( error ) return thunkAPI.rejectWithValue( error.errors );
 }
);

export const userProfileOverView = createAsyncThunk(
 'profile/userProfileOverView',
 async ( payload: void , thunkAPI ) => {
  const { data, error } = await ProfileFacadeService.profileOverView( );
  if ( data ) return data;

  if ( error ) return thunkAPI.rejectWithValue( error.errors );
 }
);

export const ProfileSlice = createSlice( {
 name: PROFILE_KEY,
 initialState: INITIAL_PROFILE_VALUE,
 reducers: {},
 extraReducers: builder => {
  builder
   .addCase( UserRegistrationForm.pending, ( state, action ) => {
    state.profileStatus = 'loading';
   } )
   .addCase( UserRegistrationForm.fulfilled, ( state, action ) => {
    state.user = action.payload;
    state.profileStatus = 'loaded';
   } )
   .addCase( UserRegistrationForm.rejected, ( state, action ) => {
    state.profileStatus = 'rejected';
   } )
   .addCase( setRegistrationForm, ( state, action ) => {
    state.user = action.payload;
   }
   )
   .addCase( getUserProfile.fulfilled, ( state, action ) => {
    state.user = action.payload;
   } )
   .addCase( userProfileOverView.fulfilled, ( state, action ) => {
    state.profileOverView = action.payload;
   } )
   .addCase( uploadProfile.fulfilled, ( state, action ) => {
    state.user = action.payload;
    state.loading = false;

   } )
   .addCase( uploadProfile.rejected, ( state, action ) => {
    state.loading = false;

   } )
   .addCase( uploadProfile.pending, ( state, action ) => {
    state.loading = true;
   } )
   .addCase( UsersListing.fulfilled, ( state, action ) => {
    state.userListing = action.payload;
   } );

 }
} );

export const profileReducer = ProfileSlice.reducer;
export const getProfileState = ( rootState: any ): InitialProfileState => rootState[ PROFILE_KEY ];
export const selectUserProfile = createSelector( getProfileState, state => state.user );
export const selectProfileStatus = createSelector( getProfileState, state => state.profileStatus );
export const selectProfileOverView = createSelector( getProfileState, state => state.profileOverView );
export const selectUserListing = createSelector( getProfileState, state => state.userListing );
export const selectUserLoading = createSelector( getProfileState, state => state.loading );
