export interface ImageProps {
  uploader_id: number,
  name: string,
  rank: number
}

export interface OwnerAttribute {
  owner_id: number,
  logo_url: string
  owner_type: string,
  name: string,
  rank: number
}


export interface PostData {
  admin_verified: boolean
  category: string,
  group_attributes: {
    logo: string,
    name:string
  }
  attachments: [
    {
      attachment_url: any
    }
  ]
  comment_count: number,
  innovator: {
    gender: string
    occupation: string
    first_name: string
    last_name: string
    logo_url: string
  },
  created_at: string,
  description: string,
  liked: boolean,
  district: string[],
  link: string,
  summary: string,
  dzongkhag: string,
  innovator_attributes: any
  first_comment_attributes: {
    id: number,
    comment: string,
    post_id: number,
    commenter_attributes: {
      commenter_id: number,
      rank: string,
      name: string
    }
  }
  funding_option: string,
  id:number
  norbucount:number
  owner_attributes: OwnerAttribute,
  proposal_status:null,
  stage: string,
  tags: Tag[],
  title: string,
  uploader_attributes: ImageProps
}

export interface Tag {
  id: number,
  name: string
}

export interface ExploreData {
  posts: PostData[],
  meta: {
    current: number,
    last_page: number,
    next_page: number,
    previous: number,
    total: number
  }
}

export interface PostComments {
    id: number,
    created_at: any,
    comment: string,
    post_id: number,
    commenter_attributes: {
      commenter_id: number,
      rank: number,
      name: string
    }

}
