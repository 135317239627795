import { loader } from '../image-shared/image-shared';
import React from 'react';

interface LoaderProps {
  height?: string
}

export function Loader ( props: LoaderProps ): JSX.Element {
 return (
  <img src={ loader.imageSrc } height={ props.height || '150px' } className='loader' alt='Logo'/>
 );
}

export default Loader;
