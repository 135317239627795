import { APIResponse, Methods } from './model/base.model';
import { toast } from 'react-hot-toast';
import { environment } from '../../../../../apps/naykab-gokab/src/environments/environment';

const baseUrl = environment.NX_SERVER_URL ;

export const FetchAPI = async <T>( url: string, method: Methods, init?: RequestInit ): Promise<APIResponse<T>> => {
 return window.fetch( `${baseUrl}/${url}`, {
  method,
  ...init,
  credentials: 'include',
  headers: {
   'accept-language': 'en',
  }
 } ).then( async response => {
  if ( response.ok || response.status < 400 ) {
   const json = await response.json();
   return { data: json };
  }
  if ( response.status === 401 ) {
   localStorage.clear();
  }
  // convert non-2xx HTTP responses into errors:
  const json = await response.json();
  toast.error( json.error || json.errors[0]  );
  return Promise.resolve( { error: json } );
 } ).catch( response => {
  return Promise.resolve( { error: { errors: [response?.toString()] } } );
 } );
};
