import { FetchAPI } from '../base-api-services/base-api.service';

export const getTagListAPI = () => FetchAPI( 'api/v1/tags', 'GET' );

export const postReportAPI = ( payload: FormData ) => FetchAPI( 'api/v1/reports', 'POST', { body: payload } );

export const postLikeAPI = ( payload: number | string ) => FetchAPI( `api/v1/posts/${payload}/norbus`,
 'POST' );

export const postUnlikeAPI = ( payload: number | string ) => FetchAPI( `api/v1/posts/${payload}/unlike`,
 'DELETE' );

export const dzongkhagDetailAPI = ( dzongkhag: string ) => FetchAPI( `api/v1/posts/statistics?dzongkhag=${dzongkhag}`, 'GET' );

export const topInnovatorsAPI = () => FetchAPI( 'api/v1/users/top_innovators', 'GET' );

export const getTestimonialAPI = ( type: string ) => FetchAPI( `api/v1/enquiries?enquiry_type=${type}`, 'GET' );

export const getAdminTestimonialAPI = ( type: string, page?: number, per_page?: number ) => FetchAPI( `api/v1/enquiries/all_enquiries?enquiry_type=${type}&per_page=${per_page}&page_number=${page}`, 'GET' );

export const postEnquiresAPI = ( payload: FormData ) => FetchAPI( 'api/v1/enquiries', 'POST', { body: payload } );

export const topSolutionAPI = () => FetchAPI( 'api/v1/posts/top_solutions', 'GET' );

export const verifiedTestimonial = ( id: number ) => FetchAPI( `api/v1/enquiries/${id}/publish`, 'PUT' );

export const deleteTestimonialAPI = ( id: number ) => FetchAPI( `api/v1/enquiries/${id}`, 'DELETE' );
