import { createTheme } from '@mui/material';

export const theme = createTheme( {
 palette: {
  primary: {
   main: '#EF629F',
   light: '#F9EAF3',
   dark: 'rgba(211, 47, 125, 0.6)'
  },
  success: {
   main: '#76D03F'
  },
  error: {
   main: '#E74C3C'
  },
  warning: {
   main: '#F39C12',
   contrastText: '#FFFFFF'
  },
  black: {
   main: '#343E56',
   light: 'rgba(0, 0, 0, 0.09)',
   dark: 'rgba(0, 0, 0, 0.87)',
   contrastText: '#75829C',
  },
  altoGray: {
   light: '#F2F2F2',
   main: '#F5F5F5',
   dark: '#F8F9FA',
   contrastText: '#979A9D',
  },
  white: {
   main: '#FFFFFF'
  },

  water: {
   main: '#72DCFD'
  }
 },

 typography: {
  fontFamily: 'Open Sans, sans-serif',

  body1: {
   fontSize: '16px',
  },

  body2: {
   fontSize: '14px',
  },
  subtitle2: {
   fontSize: '12px',
  }
 },

 components: {
  MuiTypography: {
   styleOverrides: {
    root: {
     fontWeight: '400'
    },
   },
  },
 },
} );

declare module '@mui/material/styles' {
  interface Palette {
    altoGray: Palette['primary'];
  }

  interface PaletteOptions {
    altoGray?: PaletteOptions['primary'];
  }

  interface Palette {
    black: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
  }

  interface Palette {
    white: Palette['primary'];
  }

  interface PaletteOptions {
    white?: PaletteOptions['primary'];
  }

  interface Palette {
    water: Palette['primary'];
  }

  interface PaletteOptions {
    water?: PaletteOptions['primary'];
  }
}
