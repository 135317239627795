export interface UserModel {
  id: number;
  email: string;
  role_id: number;
  exposures: any
  fb?: string
  profile_attributes: Profile
}

export interface Profile {
  bio: string
  dob: string
  first_name: string
  id: number
  exposures: any
  name?: string
  last_name: string
  occupation: string
  rank: number
  logo: string
  social_links: any
}

export interface RegistrationForm {
  phone?: string,
  cid?: any,
  firstName?: string,
  lastName?: string,
  dob?: string,
  gender?: string,
  occupation?: string,
}

export interface EditBasicProfile {
  first_name?: string,
  last_name?: string,
  bio: string,
  occupation?: string,
  dob?: string,
  social_links: SocialLink
}

export interface ProfileSetUp {
  uid?: number
  profile: EditBasicProfile
}

export interface SocialLink {
  fb: string,
  linkedin: string
}

export interface ProfileUpdateReturn {
  bio: string
  cover_photo_url: string
  role_id: number
  current_address: null
  dob: string
  exposures: Exposures[]
  first_name: string
  id: number
  logo_url: string
  last_name: string
  occupation: string
  rank: number
  social_links: SocialLink
  user: {
    id: number,
    email: string
  }
}

export interface Exposures {
  id: number
  name: string,
  exposure_type: string,
  start_date: string,
  end_date: string,
  as: string
}
