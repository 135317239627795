import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { ExploreFacadeService } from '../explore-facade.service';
import { ExploreData, PostData } from '../model/explore.model';

export const EXPLORE_KEY = 'explore';

export interface InitialExploreState {
 exploreData: ExploreData | null
 exploreQuestion: ExploreData | null,
 explorePublish: ExploreData | null,
 exploreLoading: string
 myPost: any
 newsAndEvent: []
 verifiedPost: ExploreData | null,
 reportedPost: PostData[] | null,
}

export const INITIAL_EXPLORE_VALUE: InitialExploreState = {
 exploreData: null,
 exploreQuestion: null,
 explorePublish: null,
 myPost: null,
 exploreLoading: 'init',
 newsAndEvent: [],
 verifiedPost: null,
 reportedPost: null
};

export const getExploreData = createAsyncThunk(
 'explore/getExplorePost',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await ExploreFacadeService.getExplorePost( payload.data || payload, payload?.post, payload?.query );
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const getExploreQuestions = createAsyncThunk(
 'explore/getExploreQuestion',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await ExploreFacadeService.getExplorePost( payload.data || payload, payload.post );
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const postComment = createAsyncThunk(
 'explore/postComment',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await ExploreFacadeService.postComments( payload.data, payload.id );
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const postVerify = createAsyncThunk(
 'explore/postVerify',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await ExploreFacadeService.postVerifiedData( payload.page, payload.number );
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const getExplorePublish = createAsyncThunk(
 'explore/getExplorePublish',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await ExploreFacadeService.getExplorePost( payload );
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 } );

export const getMyPost = createAsyncThunk(
 'explore/getMyPost',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await ExploreFacadeService.getMyPost( payload );
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const deleteMyPost = createAsyncThunk(
 'explore/deleteMyPost',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await ExploreFacadeService.deleteMyPost( payload );
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const getReportedPost = createAsyncThunk(
 'explore/getReportedPost',
 async ( payload: void, thunkAPI ) => {
  const { data, error } = await ExploreFacadeService.getReportedPost( );
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const getNewsAndEvent = createAsyncThunk(
 'explore/getNewsAndEvent',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await ExploreFacadeService.newsAndEvent( payload );
  if ( data ) {
   return data.news_events;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const ExploreSlice = createSlice( {
 name: EXPLORE_KEY,
 initialState: INITIAL_EXPLORE_VALUE,
 reducers: {},
 extraReducers: builder => {
  builder
   .addCase( getExploreData.fulfilled, ( state, action ) => {
    state.exploreData = action.payload;
    state.exploreLoading = 'loaded';

   } )
   .addCase( getExploreData.pending, ( state, action ) => {
    state.exploreLoading = 'loading';
   } )
   .addCase( getExploreData.rejected, ( state, action ) => {
    state.exploreLoading = 'init';
   } )
   .addCase( getExploreQuestions.fulfilled, ( state, action ) => {
    state.exploreQuestion = action.payload;
    state.exploreLoading = 'loaded';
   } )
   .addCase( getExploreQuestions.pending, ( state, action ) => {
    state.exploreLoading = 'loading';
   } )
   .addCase( getExploreQuestions.rejected, ( state, action ) => {
    state.exploreLoading = 'init';
   } )
   .addCase( getExplorePublish.fulfilled, ( state, action ) => {
    state.explorePublish = action.payload;
    state.exploreLoading = 'loaded';

   } )
   .addCase( getExplorePublish.pending, ( state, action ) => {
    state.exploreLoading = 'loading';
   } )
   .addCase( getExplorePublish.rejected, ( state, action ) => {
    state.exploreLoading = 'init';
   } )

   .addCase( getMyPost.fulfilled, ( state, action ) => {
    state.myPost = action.payload;
   } )
   .addCase( getNewsAndEvent.fulfilled, ( state, action ) => {
    state.newsAndEvent = action.payload;
   } )
   .addCase( postVerify.fulfilled, ( state, action ) => {
    state.verifiedPost = action.payload;
   } )
   .addCase( getReportedPost.fulfilled, ( state, action ) => {
    state.reportedPost = action.payload;
   } )
  ;
 }
} );

export const exploreReducer = ExploreSlice.reducer;
export const getExploreState = ( rootState: any ): InitialExploreState => rootState[ EXPLORE_KEY ];
export const selectExploreData = createSelector( getExploreState, state => state.exploreData );
export const selectExploreQuestion = createSelector( getExploreState, state => state.exploreQuestion );
export const selectExplorePublish = createSelector( getExploreState, state => state.explorePublish );
export const selectExploreLoading = createSelector( getExploreState, state => state.exploreLoading );
export const selectMyActivity = createSelector( getExploreState, state => state.myPost );
export const selectNewsAndEvent = createSelector( getExploreState, state => state.newsAndEvent );
export const selectVerifyPost = createSelector( getExploreState, state => state.verifiedPost );
export const selectReportedPost = createSelector( getExploreState, state => state.reportedPost );
