import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { Toaster } from 'react-hot-toast';
import { theme } from './assets/theme';

const root = ReactDOM.createRoot(
  document.getElementById( 'root' ) as HTMLElement
);
root.render(
 <StrictMode>
  <Toaster gutter={24}
   position='top-center'
   toastOptions={{
    style: {
     padding: '12px',
     background: theme.palette.black.contrastText,
     color: theme.palette.white.main,
    },
   }}
  />
  <BrowserRouter>
   <Provider store={store}>
    <App />
   </Provider>
  </BrowserRouter>
 </StrictMode>
);
