import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { setCreatePostStatus, setNavValue, setTheme } from './shared-state.action';
import { SharedFacadeService } from '../shared-facade.service';
import { Tags } from '../model/shared-data.model';

export const SHARED_KEY = 'shared';

export interface InitialSharedState {
  theme: string,
  tagList: Tags [] | [],
  sideNave: boolean,
  postCreation: boolean
  statisticDzongkhag: any
  innovator: any
  testimonial: any
  topSolution: any;
}

export const INITIAL_SHARED_VALUE: InitialSharedState = {
 theme: 'kk',
 tagList: [],
 sideNave: false,
 postCreation: false,
 statisticDzongkhag: undefined,
 innovator: [],
 testimonial: [],
 topSolution: undefined

};

export const getTagList = createAsyncThunk(
 'shared/getTagList',
 async ( payload: void, thunkAPI ) => {
  const { data, error } = await SharedFacadeService.getTagList();
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const getDzongkhagDetail = createAsyncThunk(
 'shared/getDzongkhagDetail',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await SharedFacadeService.dzongkhagDetail( payload );
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const getTopInnovator = createAsyncThunk(
 'shared/getTopInnovator',
 async ( payload: void, thunkAPI ) => {
  const { data, error } = await SharedFacadeService.topInnovators();
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const getTopPost= createAsyncThunk(
 'shared/getTopPost',
 async ( payload: void, thunkAPI ) => {
  const { data, error } = await SharedFacadeService.getTopSolution();
  if ( data ) {
   return data;
  }
  if ( error ) {
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);

export const getUserTestimonial = createAsyncThunk(
 'shared/getUserTestimonial',
 async ( payload: any, thunkAPI ) => {
  const { data, error } = await SharedFacadeService.getTestimonial( payload.type, payload.isAdmin, payload.page, payload.per_page  );
  if ( data ) {
   return data;
  }
  if ( error ){
   return thunkAPI.rejectWithValue( error.errors );
  }
 }
);


export const SharedSlice = createSlice( {
 name: SHARED_KEY,
 initialState: INITIAL_SHARED_VALUE,
 reducers: {},
 extraReducers: builder => {
  builder
   .addCase( setTheme, ( state, action ) => {
    state.theme = action.payload;
   } )
   .addCase( setNavValue, ( state, action ) => {
    state.sideNave = action.payload;
   } )
   .addCase( setCreatePostStatus, ( state, action ) => {
    state.postCreation = action.payload;
   } )
   .addCase( getTagList.fulfilled, ( state, action ) => {
    state.tagList = action.payload;
   } )

   .addCase( getDzongkhagDetail.fulfilled, ( state, action ) => {
    state.statisticDzongkhag = action.payload;
   } )
   .addCase( getTopInnovator.fulfilled, ( state, action ) => {
    state.innovator = action.payload;
   } )
   .addCase( getUserTestimonial.fulfilled, ( state, action ) => {
    state.testimonial = action.payload;
   } )
   .addCase( getTopPost.fulfilled, ( state, action ) => {
    state.topSolution = action.payload;
   } )
  ;
 }
} );

export const sharedReducer = SharedSlice.reducer;
export const getSharedState = ( rootState: any ): InitialSharedState => rootState[ SHARED_KEY ];
export const selectCurrentTheme = createSelector( getSharedState, state => state.theme );
export const selectTagList = createSelector( getSharedState, state => state.tagList );
export const selectNav = createSelector( getSharedState, state => state.sideNave );
export const selectPostCreation = createSelector( getSharedState, state => state.postCreation );
export const selectDzongkhag =  createSelector( getSharedState, state => state.statisticDzongkhag );
export const selectGetInnovator = createSelector( getSharedState, state => state.innovator );
export const selectTestimonial = createSelector( getSharedState, state => state.testimonial );
export const selectTopSolution = createSelector( getSharedState, state => state.topSolution );
