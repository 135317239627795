import { Tags } from '../model/shared-data.model';

export const TAGS: Tags[] = [
 { name: 'public_sector_innovation', label: 'Public Sector Innovation', id: 426 },
 { name: 'digital_forms', label: 'Digital Forms', id: 427 },
 { name: 'digital_participation', label: 'Digital Participation', id: 428 },
 { name: 'digital_product', label: 'Digital Product', id: 429 },
 { name: 'digital_services', label: 'Digital Services', id: 430 },
 { name: 'digital_solution_for_health', label: 'Digital Solution for Health', id: 431 },
 { name: 'digital_solution_for_health_public_sector_innovation_e_service', label: 'Digital Solution for Health Public Sector Innovation E-service', id: 432 },
 { name: 'fintech', label: 'Fintech', id: 433 },
 { name: 'private_sector_support_renewable_energy', label: 'Private Sector Support Renewable Energy', id: 434 },
 { name: '3d_printing', label: '3d Printing', id: 435 },
 { name: 'access_to_basic_services', label: 'Access to Basic Services', id: 436 },
 { name: 'access_to_education', label: 'Access to Education', id: 437 },
 { name: 'accessibility', label: 'Accessibility', id: 438 },
 { name: 'accountability',label: 'Accountability', id: 439 },
 { name: 'adolescents',label: 'Adolescents', id: 440 },
 { name: 'adventure_tourism',label: 'Adventure Tourism', id: 441 },
 { name: 'affordable_energy',label: 'Affordable Energy', id: 442 },
 { name: 'affordable_housing',label: 'Affordable Housing', id: 443 },
 { name: 'agile_management',label: 'Agile Management', id: 444 },
 { name: 'aging',label: 'Aging', id: 445 },
 { name: 'agricultural_mechanization',label: 'Agricultural Mechanization', id: 446 },
 { name: 'agricultural_waste',label: 'Agricultural Waste', id: 447 },
 { name: 'agritourism',label: 'Agritourism', id: 448 },
 { name: 'alternative_energy',label: 'Alternative Energy', id: 449 },
 { name: 'artificial_intelligence',label: 'Artificial Intelligence', id: 450 },
 { name: 'augmented_reality',label: 'Augmented Reality', id: 451 },
 { name: 'behavioral_insights',label: 'Behavioral Insights', id: 452 },
 { name: 'big_data',label: 'Big Data', id: 453 },
 { name: 'biomimicry',label: 'Biomimicry', id: 454 },
 { name: 'bio_waste',label: 'Bio Waste', id: 455 },
 { name: 'biotechnology',label: 'Biotechnology', id: 456 },
 { name: 'carbon_emission',label: 'Carbon Emission', id: 457 },
 { name: 'circular_economy',label: 'Circular Economy', id: 458 },
 { name: 'citizen_engagement_and_feedback',label: 'Citizen Engagement and Feedback', id: 459 },
 { name: 'citizen_science',label: 'Citizen Science', id: 460 },
 { name: 'civic_participation',label: 'Civic Participation', id: 461 },
 { name: 'clean_cities',label: 'Clean Cities', id: 462 },
 { name: 'clean_energy',label: 'Clean Energy', id: 463 },
 { name: 'climate_change',label: 'Climate Change', id: 464 },
 { name: 'community_empowerment',label: 'Community Empowerment', id: 465 },
 { name: 'community_engagement',label: 'Community Engagement', id: 466 },
 { name: 'connectivity',label: 'Connectivity', id: 467 },
 { name: 'crowdfunding',label: 'Crowdfunding', id: 468 },
 { name: 'data_science',label: 'Data Science', id: 469 },
 { name: 'digital_economy',label: 'Digital Economy', id: 470 },
 { name: 'digital_public_goods',label: 'Digital Public Goods', id: 471 },
 { name: 'digital_platform',label: 'Digital Platform', id: 472 },
 { name: 'digital_solution',label: 'Digital Solution', id: 473 },
 { name: 'disabilities',label: 'Disabilities', id: 474 },
 { name: 'disaster_risk_reduction', label: 'Disaster Risk Reduction' , id: 475 },
 { name: 'waste',label: 'Waste', id: 476 },
 { name: 'e_commerce',label: 'e-commerce', id: 477 },
 { name: 'e_governance',label: 'e-governance', id: 478 },
 { name: 'emerging_technologies',label: 'Emerging Technologies', id: 479 },
 { name: 'employment',label: 'Employment', id: 480 },
 { name: 'youth_employment',label: 'Youth Employment', id: 481 },
 { name: 'energy',label: 'Energy', id: 482 },
 { name: 'entrepreneurship',label: 'Entrepreneurship', id: 483 },
 { name: 'food_security',label: 'Food Security', id: 484 },
 { name: 'gamification',label: 'Gamification', id: 485 },
 { name: 'gender_equality',label: 'Gender Equality', id: 486 },
 { name: 'governance',label: 'Governance', id: 487 },
 { name: 'green_technology',label: 'Green Technology', id: 488 },
 { name: 'housing',label: 'Housing', id: 489 },
 { name: 'informal_business',label: 'Informal Business', id: 490 },
 { name: 'inclusive_growth',label: 'Inclusive Growth', id: 491 },
 { name: 'innovation',label: 'Innovation', id: 492 },
 { name: 'innovation_challenges',label: 'Innovation Challenges', id: 493 },
 { name: 'innovative_financing',label: 'Innovative Financing', id: 494 },
 { name: 'investment',label: 'Investment', id: 495 },
 { name: 'data_management',label: 'Data Management', id: 496 },
 { name: 'local_government',label: 'Local Government', id: 497 },
 { name: 'msmes',label: 'Msmes', id: 498 },
 { name: 'nature_based_solutions',label: 'Nature Based Solutions', id: 499 },
 { name: 'participatory_design',label: 'Participatory Design', id: 500 },
 { name: 'hackathons',label: 'Hackathons', id: 501 },
 { name: 'systems_thinking',label: 'Systems Thinking', id: 502 },
 { name: 'design_thinking',label: 'Design Thinking', id: 503 },
 { name: 'process_innovation',label: 'Process Innovation', id: 504 },
 { name: 'plastic_waste_management',label: 'Plastic Waste Management', id: 505 },
 { name: 'partnership', label: 'Partnership', id: 506 },
 { name: 'public_services', label: 'Public Services', id: 507 },
 { name: 'recycling', label: 'Recycling', id: 508 },
 { name: 'remote_work', label: 'Remote Work', id: 509 },
 { name: 'rural_development', label: 'Rural Development', id: 510 },
 { name: 'solar_energy', label: 'Solar Energy', id: 511 },
 { name: 'social_enterprise', label: 'Social Enterprise', id: 512 },
 { name: 'startup', label: 'Startup', id: 513 },
 { name: 'sustainability', label: 'Sustainability', id: 514 },
 { name: 'sustainable_energy', label: 'Sustainable Energy', id: 515 },
 { name: 'youth_empowerment', label: 'Youth Empowerment', id: 516 },
 { name: 'waste_management', label: 'Waste Management', id: 517 },
 { name: 'female_employment', label: 'Female Employment', id: 518 },
 { name: 'zero_waste', label: 'Zero Waste', id: 519 },
 { name: 'livelihood', label: 'Livelihood', id: 520 },
 { name: 'transportation_mobility', label: 'Transportation Mobility', id: 521 },
 { name: 'digital_technology', label: 'Digital Technology', id: 522 },
 { name: 'agritech', label: 'Agri Tech', id: 523 },
 { name: 'education', label: 'Education', id: 524 },
 { name: 'business_trade_finance', label: 'Business Trade Finance', id: 525 },
 { name: 'agriculture', label: 'Agriculture', id: 526 },
];

export const URL_REGEX = '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$\n';
