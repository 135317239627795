export const DefaultImage = {
 imageSrc: '/assets/images/profile.png',
};

export const DefaultCover = {
 imageSrc: '/assets/images/bg-cover.jpg',
};

export const BackgroundKhorlo = {
 imageSrc: '/assets/images/create-group-logo.svg',
};

export const LogoSvg = {
 imageSrc: '/assets/images/logo.svg',
};

export const LightLogoSvg = {
 imageSrc: '/assets/images/light-logo.svg',
};

export const HomeLogo = {
 imageSrc: '/assets/images/home-logo.svg',
};

export const RANK = {
 1: '/assets/images/rank-1.svg',
 2: '/assets/images/rank-2.svg',
 3: '/assets/images/rank-3.svg',
 4: '/assets/images/rank-4.svg',
 5: '/assets/images/rank-5.svg',
 6: '/assets/images/rank-6.svg',
 7: '/assets/images/rank-7.svg',
 8: '/assets/images/rank-8.svg',
 9: '/assets/images/rank-9.svg',
 10: '/assets/images/rank-10.svg',
};

export const HOME_SLIDER = [
 {
  id: '1',
  imagSrc: '/assets/images/image-1.jpeg',
  label: 'UNDP Bhutan'
 },  {
  id: '2',
  imagSrc: '/assets/images/image-2.jpeg',
  label: 'UNDP Bhutan'
 },
 {
  id: '4',
  imagSrc: '/assets/images/image-4.jpeg',
  label: 'UNDP Bhutan'
 },
 {
  id: '8',
  imagSrc: '/assets/images/image-8.jpeg',
  label: 'UNDP Bhutan'
 },
 {
  id: '9',
  imagSrc: '/assets/images/image-9.jpg',
  label: 'UNDP Bhutan'
 },
 {
  id: '10',
  imagSrc: '/assets/images/image-10.jpg',
  label: 'UNDP Bhutan'
 },
 {
  id: '11',
  imagSrc: '/assets/images/image-11.jpg',
  label: 'UNICEF Bhutan'
 },
 {
  id: '12',
  imagSrc: '/assets/images/image-12.jpg',
  label: 'UNICEF Bhutan'
 }
];



export const RANK_DAR = {
 1: '/assets/images/first_khader.svg',
 2: '/assets/images/second_khader.svg',
 3: '/assets/images/third_khader.svg',
};

export const DefaultExplorerCover = {
 imageSrc: 'https://images.unsplash.com/photo-1544240477-b87ce5315b49?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1828&q=80',
};

export const ExplorerLogo = {
 imageSrc: '/assets/images/explorer-logo.png',
};

export const EnFlag = {
 imageSrc: '/assets/images/btn.png',
};

export const DzFlag = {
 imageSrc: '/assets/images/uk.png',
};

export const IdeaLogo = {
 imageSrc: '/assets/images/idea-logo.svg',
};

export const Norbus = {
 imageSrc: '/assets/images/norbus.svg',
};

export const SignupFooterLogo = {
 imageSrc: '/assets/images/signup-footer-logo.svg',
};

export const Facebook = {
 imageSrc: '/assets/images/facebook.svg',
};

export const Google = {
 imageSrc: '/assets/images/google.svg',
};

export const Linkedin = {
 imageSrc: '/assets/images/linkedin.svg',
};

export const Bhutan = {
 imageSrc: '/assets/images/bhutan.svg',
};

export const AuthTopLogo = {
 imageSrc: '/assets/images/auth-top-logo.svg',
};

export const AuthRightSideImage = {
 imageSrc: '/assets/images/auth-right-side.svg',
};

export const Khorlo = {
 imageSrc: '/assets/images/khorlo.svg',
};

export const WelcomeGroup = {
 imageSrc: '/assets/images/welcome-icon.svg',
};

export const HomeFirstIdea = {
 imageSrc: '/assets/images/home-first-idea.jpg',
};

export const HomeSecondIdea = {
 imageSrc: '/assets/images/home-second-idea.jpg',
};


export const HomeThirdIdea = {
 imageSrc: '/assets/images/home-third-idea.jpg',
};

export const FaqBackground = {
 imageSrc: '/assets/images/faq.svg',
};

export const ConfirmationImage = {
 imageSrc: '/assets/images/confirmation.svg',
};

export const liked_norbu = {
 imageSrc: '/assets/images/liked-norbu.svg',
};

export const NoSearch = {
 imageSrc: '/assets/images/no-search.svg',
};

export const loader = {
 imageSrc: '/assets/images/loader.svg',
};

export const ABOUT_US_PLATFORM = {
 imageSrc: '/assets/images/about-us-page.svg',
};

export const DHI = {
 imageSrc: '/assets/images/dhi.png',
};

export const MOEA = {
 imageSrc: '/assets/images/moea.jpg',
};

export const UNDP = {
 imageSrc: '/assets/images/undp.svg',
};

