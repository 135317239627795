import { UserModel } from '../credentail-services/model/credentail.model';

export const loginDataModifier = ( profileData: UserModel ): any => {
 return {
  id: profileData.id,
  email: profileData.email,
  role_id: profileData.role_id,
  social_links: profileData.profile_attributes.social_links,
  dp: profileData.profile_attributes.logo,
  exposures: profileData.exposures,
  profile_attributes: {
   first_name: profileData.profile_attributes.first_name,
   last_name: profileData.profile_attributes.last_name,
   name: `${profileData.profile_attributes.first_name} ${profileData.profile_attributes.last_name}`,
   bio: profileData.profile_attributes.bio,
   dob: profileData.profile_attributes.dob,
   id: profileData.profile_attributes.id,
   occupation: profileData.profile_attributes.occupation,
   rank: profileData.profile_attributes.rank
  }
 };
};
