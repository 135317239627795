import { Exposures, ProfileUpdateReturn } from '../credentail-services/model/credentail.model';

export const dataModifier = ( profileData: ProfileUpdateReturn ): any => {

 return {
  id: profileData.user.id,
  email: profileData.user.email,
  fb: profileData.social_links.fb,
  linkedin: profileData.social_links.linkedin,
  education: profileData.exposures.filter( ( item: Exposures ) => item?.exposure_type === 'education' ?  item : null ),
  work: profileData.exposures.filter( ( item ) => item?.exposure_type === 'work' ?  item: null ),
  profile_attributes: {
   cover: profileData.cover_photo_url ? profileData?.cover_photo_url : null,
   logo:  profileData.logo_url ? profileData.logo_url : null ,
   first_name: profileData.first_name,
   last_name: profileData.last_name,
   name: `${profileData.first_name} ${profileData.last_name}`,
   bio: profileData.bio,
   dob: profileData.dob,
   id: profileData.id,
   occupation: profileData.occupation,
   rank: profileData.rank
  }
 };
};
