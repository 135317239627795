import { serialize } from 'object-to-formdata';
import {
 blockUserAPI,
 deleteUserAPI,
 LoginAPI,
 LogOutAPI, resendCodeAPI, resetNewPasswordAPI, resetPasswordAPI, resetPasswordToken,
 SignUpAPI,
 uploadDPAPI,
 UserRegistrationAPI,
 UserVerificationAPI
} from './credentail-services';
import { LoginWithEmailData, SignUpFormData } from '../shared-service/model/shared-data.model';
import { ProfileSetUp } from './model/credentail.model';

export class CredentialFacadeService {

 static userSignIn ( payload: LoginWithEmailData ): any {
  return LoginAPI( serialize( payload ) );
 }

 static UserLogout ( ): any {
  return LogOutAPI();
 }

 static UserSignup ( payload:SignUpFormData ): any {
  return SignUpAPI( serialize( payload ) );
 }

 static uploadProfile ( payload: any ): any {
  const data = {
   profile: payload.profile
  };
  return uploadDPAPI( serialize( data ) );
 }

 static UserVerification ( confirmation_token:string, email: string ): any {
  return UserVerificationAPI( confirmation_token, email );
 }

 static codeConfirmationTokenVerify ( email: string, token: string ): any {
  return resetPasswordToken( email, token );
 }

 static resetPassword ( email: string ) : any {
  return resetPasswordAPI( email );
 }

 static UserRegistration ( payload: ProfileSetUp ): any {
  const data = {
   profile: payload.profile
  };
  return UserRegistrationAPI( serialize( data ), payload.uid );
 }

 static newPasswordSet ( payload: any ): any {
  return resetNewPasswordAPI( serialize( payload ) );
 }

 static resendCode ( payload: any ): any {
  return resendCodeAPI( serialize( payload ) );
 }

 static deleteUser ( payload: any ): any{
  return deleteUserAPI( payload );
 }

 static blockUser ( payload: any ): any{
  return blockUserAPI( payload );
 }
}
