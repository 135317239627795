export interface LoginWithEmailData{
 user?: UserData;
 email?: string
 type?: boolean
}

export interface SignUpFormData{
  sign_up: UserData
}

export interface UserData {
  email: string,
  password: string
}

export interface TagList {
  tags: Tags [],
}

export interface Tags {
  id: number,
  name: string
  label: string
}
